<template>
  <div class="lg:mx-8 mx-5 md:py-10 py-7">
    <div class="flex items-center justify-between md:mb-7 mb-5">
      <p class="text-eggplant font-semibold text-xl capitalize">{{ $t('outgoing shipments') }}</p>
      <div class="flex items-center invoice-btn p-1 sm:px-2 cursor-pointer" @click="paymentActive = true">
        <span class="sm:block hidden material-icons text-lg rtl-rotate-icon">west</span>
        <span class="sm:block hidden text-sm font-semibold uppercase ml-1 mr-2">{{ $t('invoice')}} </span>
        <img src="@/assets/images/active-invoice-icon.svg" alt="">
      </div>
    </div>
    <!-- WEB VIEW -->
    <div class="md:block hidden">
      <header-row class="text-sm" :header="header"/>
      <div class="my-4">
        <div v-for="(item, index) in shipments" :key="index" class="card-row">
          <div @click="showProductDetails(index)" 
            :class="`card-row-header mt-3 py-3 px-3 grid-cols-${header.length} font-normal text-xs`"
            :style="activeIndex === index ? 'background-color: #EEF5FC;' : 'background-color: #fff;'">
            <p class="font-medium ml-3 px-1">{{ item.tracking_number }}</p>
            <p class="mx-1">{{ item.product_set.length }}</p>
            <p class="mx-1">{{ item.total_weight }}</p>
            <div class="mx-1">
              <p>{{ item.box_size ? item.box_size.name : '' }}</p>
              <p>{{ item.box_size ? item.box_size.d_width + 'x' + item.box_size.d_height + 'x' + item.box_size.d_length : ''}} </p>
            </div>
            <p class="mx-1">{{ item.custom_fees ? item.custom_fees : 0 + ' ' + $t('EGP')}}</p>
            <p class="mx-1">{{ item.shipping_fees ? item.shipping_fees :  0 + ' ' + $t('EGP')}}</p>
            <p class="mx-1">{{ item.dispatch_date }}</p>
            <p class="mx-1">{{ item.arrival_date }}</p>
            <div class="flex items-start justify-center items-center px-1" :class="item.status">
              <p class="status-border"></p>
              <p class="capitalize ml-1 w-full">{{ item.status ? item.status.split('_').join(' ') : ''}}</p>
            </div>
            <div class="outgoing-collapse-web-icon">
              <span v-if="activeIndex === index" class="material-icons text-base">remove</span>
              <span v-else class="material-icons text-base">add</span>
            </div>
          </div>
          <div v-if="activeIndex === index" class="p-4">
            <div class="grid grid-cols-5 gap-2 text-xs card-row-inside-header p-3">
              <p>{{ $t('Product Image') }}</p>
              <p>{{ $t('Product Name') }}</p>
              <p>{{ $t('Product ID') }}</p>
              <p>{{ $t('Custom Fees') }}</p>
              <p>{{ $t('Online Store') }}</p>
            </div>
            <div class="card-row mt-3 p-3 grid grid-cols-5 gap-2 items-center" v-for="(product, productIndex) in item.product_set" :key="productIndex"> <!-- FOR LOOP -->
              <img class="product-img w-10 h-10 m-auto" :src="product.image_urls ? product.image_urls[0] : ''" alt="">
              <p class="capitalize">{{ product.name }}</p>
              <p>{{ product.id }}</p>
              <p class="font-normal">{{ product.price ? product.price : 0 + $t('EGP') }}</p>
              <p class="font-normal capitalize">{{ product.store ? product.store.name : 'N/A' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MOBILE VIEW -->
    <div class="md:hidden block" style="margin-bottom: 150px !important;">
      <div v-for="(item, index) in shipments" :key="index" class="relative card-row mt-3 p-4">
        <div @click="showProductDetails(index)" class="cursor-pointer">
          <p class="font-medium text-left">
            <span class="text-xs text-darkblue">{{ $t('Tracking Number') }}</span>
            <span class="text-2xs text-blue"> ( 843229 )</span>
          </p>
          <div class="flex justify-between text-2xs font-medium text-blue">
            <div class="text-left">
              <p>{{ '15 Oct 2022' }}</p>
              <p class="text-3xs text-gray">{{ $t('( Estimated Arrival date )') }}</p>
            </div>
            <div class="flex justify-center items-center" :class="item.status">
              <p class="status-border"></p>
              <p class="capitalize ml-1 w-full">{{ item.status ? item.status.split('_').join(' ') : ''}}</p>
            </div>
          </div>
          <div class="grid grid-cols-2 divide-x divide-gray-300 mt-2 text-2xs">
            <div class="pr-1">
              <div class="flex items-center justify-between">
                <p class="text-darkblue">{{ $t('Total weight') }}</p>
                <p class="font-medium text-cyan">{{ '30' + ' ' + $t('kg') }}</p>
              </div>
              <div class="flex items-center justify-between">
                <p class="text-darkblue">{{ $t('Customs Fees') }}</p>
                <p class="font-medium text-cyan">{{ '0.00' + ' ' }} <span class="text-3xs">{{ $t('EGP') }}</span></p>
              </div>
            </div>
            <div class="pl-1">
              <div class="flex items-center justify-between">
                <p class="text-darkblue">{{ $t('Box size') }}</p>
                <p class="font-medium text-cyan capitalize">{{ 'medium' }}</p>
              </div>
              <div class="flex items-center justify-between">
                <p class="text-darkblue">{{ $t('Shipping Fees') }}</p>
                <p class="font-medium text-cyan">{{ '0.00' + ' ' }} <span class="text-3xs">{{ $t('EGP') }}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div @click="showProductDetails(index)" class="received-collapse-mobile-icon">
          <span v-if="activeIndex === index" class="material-icons cursor-pointer">expand_less</span>
          <span v-else class="material-icons">expand_more</span>
        </div>
        <div v-if="activeIndex === index">
          <div class="hr-border mt-5 mx-3" />
          <div class="mt-4">
            <div v-for="(item, index) in 3" :key="index" class="card-row mt-2 px-4 py-2 flex items-center">
              <img class="product-img w-10 h-10" src="@/assets/images/register-image.svg" alt="">
              <div class="ml-4 w-full">
                <p class="capitalize text-xs font-medium text-left">
                  <span>{{ 'product name' }}</span>
                  <span class="ml-1 text-3xs text-darkgray">{{ '(' + '354638' + ')' }}</span>
                </p>
                <div class="mt-1 flex items-center justify-between text-left">
                  <div>
                    <p class="text-gray text-3xs">{{ $t('Custom Fees') }}</p>
                    <p class="text-blue text-xs">{{ '0' + ' '}} <span class="text-2xs">{{ $t('EGP') }}</span></p>
                  </div>
                  <div>
                    <p class="text-gray text-3xs">{{ $t('Online Store') }}</p>
                    <p class="text-xs capitalize">{{ 'Online Store' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PAYMENT CARD -->
    <div :class="paymentActive ? 'invoice-container' : ''">
      <div class="invoice-card" :class="paymentActive ? 'show-invoice-card' : 'hide-invoice-card'">
        <div class="sm:px-7 px-3 payment-border sm:pt-9 py-5">
          <div class="flex items-center justify-between payment-border pb-3">
            <p class="uppercase text-blue font-semibold leading-tight">{{ $t('invoice') }}</p>
            <span class="material-icons-round cursor-pointer text-gray text-xl" @click="paymentActive = false">highlight_off</span>
          </div>
          <div class="payment-border py-3">
            <p class="capitalize text-sm font-semibold text-darkblue leading-tight">{{ $t('sub total') }}</p>
          </div>
          <div class="font-medium leading-tight payment-border pt-1 pb-4">
            <div v-for="(shipment, index) in shipments" :key="index">
              <div class="mt-3">
                <p class="text-sm font-semibold text-darkblue capitalize"> {{ $t('package') + ' ' + (index + 1) }}</p>
                <div class="px-2">
                  <div v-for="(item, index) in shipment.product_set" :key="index" class="flex items-center justify-between text-sm mt-2">
                    <p class="font-normal">
                      <span class="text-blue">{{ $t('Custom fees' + ' ') }}</span>
                      <span class="text-darkblue capitalize sm:inline block">{{ '(' + item.name + ') :' }}</span>
                    </p>
                    <p class="text-darkblue">{{ 0 }} <span class="text-2xs">{{ $t('EGP') }}</span></p>
                  </div>
                  <div class="flex items-center justify-between text-sm mt-2 font-semibold">
                    <p class="text-blue">{{ $t('Shipping Fees') }}</p>
                    <p class="text-darkblue">{{ 0 }} <span class="text-2xs">{{ $t('EGP') }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between leading-tight text-sm py-3 font-semibold">
            <p class="text-blue">{{ $t('VAT (14%)') }}</p>
            <p class="text-darkblue">{{ 0 }} <span class="text-2xs">{{ $t('EGP') }}</span></p>
          </div>
        </div>
        <div class="sm:px-7 px-3 pt-4">
          <div class="flex items-center text-lg justify-between font-semibold">
            <p class="text-darkblue"> {{ $t('Total') }}</p>
            <p class="text-blue">{{ 0 }} <span class="text-2xs">{{ $t('EGP') }}</span></p>
          </div>
          <div class="mt-3">
            <button class="capitalize sm:text-base text-sm font-semibold text-white w-full payment-btn p-3">{{ $t('pay now') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendRequest } from '@/axios/requestHelper'
import HeaderRow from '@/layouts/customer/components/HeaderRow.vue'

export default {
  data () {
    return {
      header: ['Tracking No.', 'No. of Products', 'Total weight', 'Box size', 'Customs Fees', 'Shipping Fees', 'Dispatch Date', 'Arrival Date', 'Status'],
      shipments: [
        {
          product_set: [],
          box_size: {
            name: '',
            d_height: 0,
            d_length: 0,
            d_width: 0
          },
          custom_fees: 0,
          shipping_fees: 0
        }
      ],
      activeIndex: '',
      paymentActive: true
    }
  },
  components: {
    HeaderRow
  },
  methods: {
    loadOutgoingShipments () {
      sendRequest(this, 'api/shipments/?po_box__box_number=', 'get', null, true,
        (response) => {
          this.shipments = response.data
        }
      )
    },
    showProductDetails (index) {
      this.activeIndex =  this.activeIndex === index ? '' : index 
    }
  },
  created () {
    this.loadOutgoingShipments()
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/my-shipblu-view.scss"></style>